

  import axios from 'axios'

  export default {

    data() {
      return {
        loading: true,
        payload: [],
        institutions: [
        
        ]
      }
    },
      async created() {

       await this.fetchInsitutions('https://api.ruralmindshift.org/api/v2/!IereoPWmrzMqGgOADp:ruralmindshift.org')

      
        console.log(this.institutions)
        for(const i in this.institutions) {
        await this.fetchData('https://api.ruralmindshift.org/api/v2/'+ this.institutions[i])
      }
    },
    methods: {
      async fetchData(url) {
        this.error = this.project = null
        // replace `getPost` with your data fetching util / API wrapper
        await axios
          .get(url)
          .then((response) => {
            if (response.data) {
              const allocation = response.data.allocation
              let data = response.data
                if (!allocation || !allocation.physical) {
                  data.allocation = {physical:[{lat:0,lng:0}]}
                }
                this.payload.push(data)
                this.$store.commit('setPayload', this.payload)

              

              console.log(this.payload)
            } else {
              this.error = 'error'
            }
        })
      },
      async fetchInsitutions(rootIdUrl) {
        const data = await axios.get(rootIdUrl)

        data.data.context.forEach(ele => {
          this.institutions.push(ele.id)
          this.$store.commit('setInstitutions', this.institutions)
        });
        
        return data
        
      }
    },
  

    async mounted() {
      
    

      //for (var i = 0; i < this.institutions.length; i++) {
        

    }
  }


