export default {
  state: {
    institutions: null
  },
  mutations: {
    setInstitutions(state, institutions) {
      state.institutions = institutions
    }
  }
}
