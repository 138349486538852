/*
* TODO Kein großer Store, sondern kleine Stores per Funktion
* https://vuex.vuejs.org/guide/modules.html
*/
import { createStore } from 'vuex'
import handlePayload from "./handlePayload";
import handleInstitutions from "./handleInstitutions";


export const store = createStore({
  modules: {
    handlePayload,
    handleInstitutions
  }
})
