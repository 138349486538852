import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    name: 'Institution',
    path: '/institutions/:id',
    component: () => import('@/views/Institution'),
    props: true
  },
  {
    name: 'About',
    path: '/about',
    component: () => import('@/views/About'),
  },
  {
    name: 'Index',
    path: '/index',
    component: () => import('@/views/Index'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
