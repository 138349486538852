import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Markdown = _resolveComponent("Markdown")

  return (_openBlock(), _createBlock(_component_Markdown, {
    "basic-text": "",
    source: $props.text,
    class: _normalizeClass([
  'text',
  'transtion-all duration-200',
    {
    'text-sm md:text-sm-md': $props.size === undefined || $props.size === 'sm',
    'text-xs': $props.size === 'xs',
  }])
  }, null, 8, ["source", "class"]))
}