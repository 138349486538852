export default {
  state: {
    payload: null
  },
  mutations: {
    setPayload(state, payload) {
      state.payload = payload
    }
  }
}
