import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33ae8852"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BasicText = _resolveComponent("BasicText")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("header", {
    "layout-header": "",
    class: _normalizeClass([
      'fixed right-0 top-0',
      'w-full',
      'flex flex-row justify-between',
      'text-white',
      _ctx.$route.name !== 'Home' ? 'bg-black' : 'mix-blend-difference'
      ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
          'flex -mt-1',
          'flex-grow-1',
          'p-2 pb-1.5',
        ])
    }, [
      _createVNode(_component_router_link, {
        to: { name: 'Home'},
        class: _normalizeClass([
          ])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicText, {
            text: 'Map',
            size: 'sm',
            class: _normalizeClass([
            'block',
            'mr-2',
            _ctx.$route.name !== 'Home'  ? 'opacity-1' : 'opacity-0',
          ])
          }, null, 8, ["class"])
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: {name: 'Index'} }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicText, {
            text: 'Index',
            size: 'sm',
            class: _normalizeClass([
            'mr-2',
            _ctx.$route.name === 'About' || _ctx.$route.name === 'Index' ? 'opacity-1' : 'opacity-0',
          ])
          }, null, 8, ["class"])
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: {name: 'About'} }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicText, {
            text: 'About',
            size: 'sm',
            class: _normalizeClass([
            _ctx.$route.name === 'About' || _ctx.$route.name === 'Index' ? 'opacity-1' : 'opacity-0',
            'mr-2'
          ])
          }, null, 8, ["class"])
        ]),
        _: 1
      })
    ], 2),
    _createVNode(_component_router_link, {
      to: {name: 'About'},
      "active-class": '',
      class: _normalizeClass([
        'block',
        'text-lg',
        'p-1.5',
        'transition-all duration-1000',
        'bg-black',
        _ctx.$route.name !== 'Home' ? 'h-8' : 'h-16'
        ])
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: '/icons/logo.svg',
          class: _normalizeClass([
          'h-full'
        ])
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}