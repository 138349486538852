

import Markdown from 'vue3-markdown-it';


export default {
  props: {
    text: String,
    size: String
  },
  components: {
    Markdown
  },
}
